.iconStyle{
    font-size: xx-small;
    margin: 0;
    margin-top: 20px;
}
.closeButtonStyle{
    font-size: xx-large
}
.titleStyle{
 color: white;   
}

.containerStyle > div{
 display: flex !important;
 flex-direction: row;
 align-items: center;
}