.datePicker{
    width: 100px !important;
    background: #fcfcfb !important;
    background-color: transparent !important;
}
.datePicker input{
padding: 6px !important;
font-size: small;
}
.datePicker :focus{
    background: white !important;
}


.gridItem{
    text-align: center;
}
.gridItem input{
   font-size: small;
}

.tableHeader{
    background-color: rgba(224, 224, 224, 1) !important;
}