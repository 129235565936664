.datePicker{
    width: 100% !important;
    background: #fcfcfb !important;
    margin-top: 10px !important;
}
.datePicker input{
padding: 13px !important;
}
.datePicker :focus{
    background: white !important;
}